import CategoryPage from '@/views/_Category.vue'; // Импортируем компонент для категории
import Login from '@/views/LoginPage.vue'
import NeedsListPage from '@/views/NeedsListPage.vue'
import ProductCatalog from '@/views/ProductCatalog.vue'
import UserProfilePage from '@/views/UserProfilePage.vue'
import { createRouter, createWebHistory } from 'vue-router'

const routes = [{
        path: '/login',
        name: 'Login',
        component: Login,
        meta: { requiresAuth: false }
    },
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/needs',
        name: 'NeedsList',
        component: NeedsListPage,
        meta: { requiresAuth: true }
    },
    {
        path: '/catalog',
        name: 'ProductCatalog',
        component: ProductCatalog,
        meta: { requiresAuth: true }
    },
    {
        path: '/catalog/:id', // Динамический маршрут для категории
        name: 'Category',
        component: CategoryPage,
        props: true, // Передача параметров как пропсы в компонент
        meta: { requiresAuth: true }
    },
    {
        path: '/profile',
        name: 'Profile',
        component: UserProfilePage,
        meta: { requiresAuth: true }
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    const isAuthenticated = !!localStorage.getItem('authToken');
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!isAuthenticated) {
            next({ name: 'Login' });
        } else {
            next();
        }
    } else if (to.matched.some(record => !record.meta.requiresAuth)) {
        if (isAuthenticated && to.name === 'Login') {
            next({ name: 'ProductCatalog' });
        } else {
            next();
        }
    } else {
        next(); // always call next()!
    }
});

export default router;