<template>
  <el-container class="product-catalog" style="margin-top: 30px;">
    <el-main>
      <el-row>
        <el-col v-for="category in categories" :key="category.id" :span="8" class="category-item">
          <el-card shadow="always" @click="openCategory(category)">
            <h3>{{ category.name }}</h3>
          </el-card>
        </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>

<script setup>
import { useAuthStore } from '@/stores/auth'
import axios from 'axios'
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'

const categories = ref([])
const router = useRouter()
const authStore = useAuthStore()

const fetchCategories = async () => {
  try {
    const categoryResponse = await axios.get('/api/categories/')
    categories.value = categoryResponse.data
  } catch (error) {
    console.error('Error fetching categories:', error)
  }
}

const openCategory = (category) => {
  // Устанавливаем заголовок категории в Pinia
  authStore.setTitlePage(category.name)
  
  // Переход на страницу категории
  router.push({ name: 'Category', params: { id: category.id } })
}

onMounted(fetchCategories)
</script>

<style scoped>
.category-item {
  cursor: pointer;
  margin-bottom: 20px;
}
</style>
