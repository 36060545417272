<template>
  <div class="calculation">
    <div class="calculation__image">
      <img :src="'https://agent.liftnet.ru' + calculationData.product_image" alt="Product Image" class="product-image"/>
    </div>
    <p>{{ calculationData.product_name }}</p>
    <p>Рекомендованная розничная цена: {{ formattedRetailPrice }}</p>
    <p v-if="isInCurrency">Текущий курс валют по ЦБ {{ calculationData.currency.toUpperCase() }}: {{ formattedCurrencyRate }}</p>
    <p v-if="isInCurrency">Рекомендованная розничная цена в рублях: {{ convertedRetailPrice }}</p>
    <div class="calculation__slider">
      <el-slider
        v-model="salePrice"
        :min="minPrice"
        :max="maxPrice"
        :marks="priceMarks"
        :step="10000"
        show-stops
      />
    </div>
    <p>Стоимость техники: {{ formattedSalePriceCurrency }}<template v-if="isInCurrency">, по текущему курсу {{ formattedSalePrice }}</template></p>
    <p class="agent-fee-block">Агентское вознаграждение: <span>{{ formattedAgentFee }}</span></p>
    
    <h3>Доставка</h3>
    <el-tabs v-model="activeTab" @tab-click="handleTabClick">
      <el-tab-pane label="Доставка до клиента">
        <el-radio-group v-model="selectedClientDeliveryOption">
          <el-radio 
            v-for="(warehouse, index) in calculationData.client_delivery_calculations" 
            :key="index" 
            :label="index">
            <template v-if="warehouse.delivery_time_in_days">
              Доставка до {{ warehouse.address }} за {{ formatDeadline(warehouse.delivery_time_in_days) }} , стоимость — {{ formatCurrency(warehouse.cost) }}.
            </template>
            <template v-else>
              Доставка до {{ warehouse.address }} находится в расчете.
            </template>
          </el-radio>
        </el-radio-group>
        <br>
        <el-button @click="showModal = true">Рассчитать стоимость доставки в другой город</el-button>
      </el-tab-pane>
      <el-tab-pane label="Самовывоз со склада">
        <el-radio-group v-model="selectedWarehouseOption">
          <el-radio 
            v-for="(warehouse, index) in formattedWarehouses" 
            :key="index" 
            :label="index">
            {{ warehouse.name }} - {{ warehouse.delivery_cost }}
          </el-radio>
        </el-radio-group>
      </el-tab-pane>
    </el-tabs>
    <el-dialog title="Введите адрес для расчета доставки" v-model="showModal">
      <el-input v-model="otherCity" placeholder="Введите адрес"></el-input>
      <template #footer>
        <el-button @click="showModal = false">Отмена</el-button>
        <el-button type="primary" @click="calculateOtherCityDelivery">Рассчитать</el-button>
      </template>
    </el-dialog>

    <div class="total-cost">
      <h3>{{ totalCostLabel }}</h3>
      <p><template v-if="isInCurrency"><b>{{ totalCostInCny }}</b>, по текущему курсу ЦБ: </template><b>{{ totalCostInRub }}</b></p>
    </div>
    <el-input v-model="paymentTerms" placeholder="Условие оплаты"></el-input>
    <el-button 
      style="margin-top: 20px;" 
      type="primary" 
      @click="createKP" 
      size="large"
      :disabled="!canCreateKP"
    >Создать КП</el-button>
  </div>
</template>

<script>
import {
  formatCurrency,
  formatDeadline,
  formattedPhoneNumber,
  roundToNearestFiveThousand,
  roundToNearestTenThousand
} from '@/utils'
import axios from 'axios'
import { ElButton, ElDialog, ElInput, ElLoading, ElMessage, ElMessageBox, ElRadio, ElRadioGroup, ElSlider, ElTabPane, ElTabs } from 'element-plus'
import { saveAs } from 'file-saver'
import { computed, onMounted, ref } from 'vue'

export default {
  components: {
    ElSlider, ElTabs, ElTabPane, ElRadio, ElRadioGroup, ElButton, ElDialog, ElInput
  },
  props: ['calculationData', 'calculationId', 'company'],
  setup(props, { emit }) {
    const salePrice = ref(null);
    const currencyRates = ref({});
    const profile = ref(null);
    const showModal = ref(false);
    const activeTab = ref('first');
    const paymentTerms = ref('');
    const selectedClientDeliveryOption = ref(null);
    const selectedWarehouseOption = ref(null);
    const otherCity = ref('');

    onMounted(() => {
      if (props.calculationData.currency !== 'RUB') {
        axios.get('/api/currency_rates/')
          .then(response => {
            currencyRates.value = response.data;
            salePrice.value = roundToNearestTenThousand(props.calculationData.recommended_retail_price * currencyRate.value);
          })
          .catch(error => console.error('Ошибка при получении курсов валют:', error));
      } else {
        salePrice.value = roundToNearestTenThousand(props.calculationData.recommended_retail_price);
      }

      axios.get('/api/profile/')
        .then(response => {
          profile.value = response.data;
        })
        .catch(error => console.error('Ошибка при получении профиля:', error));
    });

    const currencyRate = computed(() => props.calculationData.currency !== 'RUB' ? (currencyRates.value[props.calculationData.currency.toLowerCase()] || 0) : 1);
    const formattedCurrencyRate = computed(() => formatCurrency(currencyRate.value, 'number'));
    const isInCurrency = computed(() => props.calculationData.currency !== 'RUB');

    const convertedRetailPrice = computed(() => formatCurrency(roundToNearestTenThousand(props.calculationData.recommended_retail_price * currencyRate.value)));
    const formattedRetailPrice = computed(() => formatCurrency(roundToNearestTenThousand(props.calculationData.recommended_retail_price), 'currency', props.calculationData.currency.toUpperCase()));
    const formattedSalePrice = computed(() => formatCurrency(salePrice.value));
    const formattedSalePriceCurrency = computed(() => props.calculationData.currency !== 'RUB' ? formatCurrency(roundToNearestFiveThousand(salePrice.value / currencyRate.value), 'currency', props.calculationData.currency.toUpperCase()) : formatCurrency(salePrice.value, 'currency', 'RUB'));

    const minPrice = computed(() => roundToNearestTenThousand(Math.floor(props.calculationData.recommended_retail_price * (props.calculationData.currency !== 'RUB' ? currencyRate.value : 1) * 0.97)));
    const maxPrice = computed(() => roundToNearestTenThousand(Math.floor(props.calculationData.recommended_retail_price * (props.calculationData.currency !== 'RUB' ? currencyRate.value : 1) * 1.30)));

    const agentFee = computed(() => {
      const baseFee = props.calculationData.base_price * 0.73 * currencyRate.value;
      const margin = salePrice.value - (baseFee + props.calculationData.utc_in_rub);
      const marginPercentage = props.calculationData.av_percentage_from_margin / 100;
      const ipPercentage = props.calculationData.av_percentage_from_ip / 100;
      const totalFee = margin * marginPercentage * ipPercentage;
      return Math.round(totalFee / 1000) * 1000;
    });

    const formattedAgentFee = computed(() => formatCurrency(agentFee.value));

    const formattedWarehouses = computed(() => {
      return props.calculationData.warehouses.map(warehouse => {
        return {
          ...warehouse,
          delivery_cost: warehouse.delivery_cost ? formatCurrency(warehouse.delivery_cost) : ''
        };
      });
    });

    const selectedDeliveryCost = computed(() => {
      if (selectedClientDeliveryOption.value !== null) {
        const selectedClientDelivery = props.calculationData.client_delivery_calculations[selectedClientDeliveryOption.value];
        return selectedClientDelivery ? selectedClientDelivery.cost : 0;
      } else if (selectedWarehouseOption.value !== null) {
        const selectedWarehouse = props.calculationData.warehouses[selectedWarehouseOption.value];
        return selectedWarehouse ? selectedWarehouse.delivery_cost : 0;
      }
      return 0;
    });

    const totalCostWithDelivery = computed(() => {
      return salePrice.value + selectedDeliveryCost.value;
    });

    const totalCostInRub = computed(() => formatCurrency(totalCostWithDelivery.value));
    const totalCostInCny = computed(() => props.calculationData.currency !== 'RUB' ? formatCurrency(roundToNearestFiveThousand(totalCostWithDelivery.value / currencyRate.value), 'currency', 'CNY') : '');

    const totalCostLabel = computed(() => {
      return selectedDeliveryCost.value > 0 ? 'Итоговая стоимость с доставкой:' : 'Итоговая стоимость без доставки:';
    });

    const priceMarks = computed(() => {
      return {
        [minPrice.value]: formattedMinPrice.value,
        [maxPrice.value]: formattedMaxPrice.value,
      };
    });

    const formattedMinPrice = computed(() => formatCurrency(minPrice.value));
    const formattedMaxPrice = computed(() => formatCurrency(maxPrice.value));

    const canCreateKP = computed(() => {
      return (
        (selectedClientDeliveryOption.value !== null || selectedWarehouseOption.value !== null) &&
        paymentTerms.value.length >= 4
      );
    });

    function updateSalePrice(value) {
      salePrice.value = value;
    }

    function calculateOtherCityDelivery() {
      const payload = {
        calculation: props.calculationId,
        address: otherCity.value
      };

      axios.post('/api/create-client-delivery/', payload)
        .then(response => {
          console.log('Delivery calculation created:', response.data);
          showModal.value = false;
          ElMessageBox.alert('Ваш запрос отправлен на расчет. Как только изменится статус вам придет уведомление в Telegram.', 'Спасибо!');
          ElMessage({
            message: 'Успешно!',
            type: 'success',
          });
        })
        .catch(error => {
          console.error('Error creating delivery calculation:', error);
          ElMessage({
            message: 'Запрос не отправлен',
            type: 'error',
          });
        });
    }

    function handleTabClick() {
      selectedClientDeliveryOption.value = null;
      selectedWarehouseOption.value = null;
    }

    async function createKP() {
      let delivery = '';
      let deliveryTime = '';

      if (selectedClientDeliveryOption.value !== null) {
        const selectedDelivery = props.calculationData.client_delivery_calculations[selectedClientDeliveryOption.value];
        delivery = `Доставка до ${selectedDelivery.address}`;
        deliveryTime = formatDeadline(selectedDelivery.delivery_time_in_days);
      } else if (selectedWarehouseOption.value !== null) {
        const selectedWarehouse = formattedWarehouses.value[selectedWarehouseOption.value];
        delivery = `Самовывоз со склада ${selectedWarehouse.name}`;
        deliveryTime = 'В наличии';
      }
      const pdfData = {
        model: props.calculationData.product_model,
        nameCompany: props.company,
        delivery: delivery,
        deliveryTime: deliveryTime,
        totalCostInCny: totalCostInCny.value,
        totalCostInRub: totalCostInRub.value,
        payment: paymentTerms.value,
        managerName: profile.value.first_name,
        managerPhone: formattedPhoneNumber(profile.value.phone),
        managerMail: profile.value.email,
        productName: props.calculationData.product_name,
        productBrandLogo: "https://agent.liftnet.ru" + props.calculationData.brand_logo,
        productBrandContent: props.calculationData.product_brand_content,
        productPhoto: "https://agent.liftnet.ru/"+ props.calculationData.product_image,
        guarantee: props.calculationData.guarantee,
        attributes: props.calculationData.specifications_offer 
      };

      const data = {
        calculation: props.calculationId,
        delivery: delivery,
        delivery_time: deliveryTime,
        delivery_cost: selectedDeliveryCost.value,
        av_for_agent_in_rub: agentFee.value,
        sale_price: salePrice.value,
        pdf_data: pdfData
      };

      const loading = ElLoading.service({
        lock: true,
        text: 'Создание КП...',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      try {
        await axios.post('/api/create-commercial-offer/', data);
        await Print(pdfData);
        emit('close-drawer');
      } catch (error) {
        console.error('Ошибка при создании КП:', error);
        ElMessage({
          message: 'Ошибка при создании КП',
          type: 'error',
        });
      } finally {
        loading.close();
      }
    }

    async function Print(data) {
      try {
        const response = await axios.post('https://crm.liftnet.ru/print-pdf/agent/', data, {
          responseType: 'blob'
        });

        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
        saveAs(pdfBlob, 'Коммерческое_Предложение.pdf');
      } catch (error) {
        console.error('Ошибка при скачивании КП:', error);
        ElMessage({
          message: 'Ошибка при скачивании КП',
          type: 'error',
        });
      }
    }

    return {
      salePrice, minPrice, maxPrice, updateSalePrice, formattedRetailPrice, currencyRate,
      convertedRetailPrice, formattedSalePrice, formattedSalePriceCurrency, formattedCurrencyRate, agentFee,
      showModal, activeTab, selectedClientDeliveryOption, selectedWarehouseOption, otherCity, calculateOtherCityDelivery,
      formattedWarehouses, handleTabClick, totalCostLabel,
      formattedMinPrice, formattedMaxPrice, priceMarks, formatDeadline, formatCurrency, createKP,
      paymentTerms, totalCostInRub, totalCostInCny, formattedAgentFee, canCreateKP, formattedPhoneNumber, isInCurrency
    };
  }
}
</script>

<style>
.calculation {
  padding: 0 10px;
  max-width: 600px;
  .el-tabs__nav {
    justify-content: flex-start;
  }
}
.agent-fee-block {
  background-color: rgb(197,230,177);
  padding: 15px;
  border-radius: 5px;
  margin-top: 10px;
  span {
    font-weight: bold;
  }
}

.total-cost {
  margin-top: 20px;
  font-size: 18px;
}

.calculation__image {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.product-image {
  max-height: 250px;
  max-width: 250px;
  object-fit: contain;
  height: auto;
  margin-bottom: 20px;
}

.calculation__slider {
  padding: 0 25px;
  margin-bottom: 25px;
}
.el-slider__stop {
  background-color: #e4e7ed; /* Color of the slider line */
}
</style>
