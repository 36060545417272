<template>
  <v-app>
    <!-- <div v-if="telegramUserId" class="telegram-user-id">{{ telegramUserId }}</div> -->
    <app-header></app-header>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { useAuthStore } from '@/stores/auth'
import { computed, onMounted } from 'vue'
import AppHeader from './components/AppHeader.vue'

export default {
  name: 'App',
  components: {
    AppHeader
  },
  setup() {
    const authStore = useAuthStore();
    const telegramUserId = computed(() => authStore.telegramUserId);

    onMounted(() => {
      const user = window.Telegram.WebApp.initDataUnsafe?.user;
      console.log(user.id)
      if (user) {
        authStore.setTelegramUserId(user.id);
      }
    });
    return {
      telegramUserId
    };
  }
}
</script>

<style>
/* Gilroy-Regular */
@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 400;
  src: url('@/assets/fonts/Gilroy-Regular.eot'); /* IE9 Compat Modes */
  src: url('@/assets/fonts/Gilroy-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('@/assets/fonts/Gilroy-Regular.woff') format('woff'), /* Modern Browsers */
       url('@/assets/fonts/Gilroy-Regular.ttf') format('truetype'); /* Safari, Android, iOS */
}

/* Gilroy-Medium */
@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 500;
  src: url('@/assets/fonts/Gilroy-Medium.eot'); /* IE9 Compat Modes */
  src: url('@/assets/fonts/Gilroy-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('@/assets/fonts/Gilroy-Medium.woff') format('woff'), /* Modern Browsers */
       url('@/assets/fonts/Gilroy-Medium.ttf') format('truetype'); /* Safari, Android, iOS */
}

/* Gilroy-Bold */
@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 700;
  src: url('@/assets/fonts/Gilroy-Bold.eot'); /* IE9 Compat Modes */
  src: url('@/assets/fonts/Gilroy-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('@/assets/fonts/Gilroy-Bold.woff') format('woff'), /* Modern Browsers */
       url('@/assets/fonts/Gilroy-Bold.ttf') format('truetype'); /* Safari, Android, iOS */
}

body {
  font-family: 'Gilroy', sans-serif;
  font-weight: 400; /* Default to regular */
  background-color: #fff;
}

h1 {
  font-weight: 700; /* Bold for headings */
}

strong {
  font-weight: 500; /* Medium for emphasized text */
}

</style>
