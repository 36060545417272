import axios from 'axios'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import VueLazyload from 'vue-lazyload'
import App from './App.vue'
import router from './router'

const app = createApp(App);
const pinia = createPinia();


app.use(pinia);
app.use(router);
app.use(VueLazyload, {
    preLoad: 1.3,
    attempt: 1
})
app.mount('#app');


axios.defaults.baseURL = 'https://agent.liftnet.ru/';
axios.interceptors.request.use(function(config) {
    const token = localStorage.getItem('authToken');
    if (token) {
        config.headers.Authorization = `Token ${token}`;
    }
    return config;
}, function(error) {
    return Promise.reject(error);
});