import "core-js/modules/es.array.push.js";
import { useAuthStore } from '@/stores/auth';
import { ArrowLeft, Menu } from '@element-plus/icons-vue';
import { ElButton, ElDrawer, ElHeader, ElIcon, ElMenu, ElMenuItem } from 'element-plus';
import 'element-plus/es/components/button/style/css'
import 'element-plus/es/components/drawer/style/css'
import 'element-plus/es/components/header/style/css'
import 'element-plus/es/components/icon/style/css'
import 'element-plus/es/components/menu/style/css'
import 'element-plus/es/components/menu-item/style/css'

import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
export default {
  name: 'AppHeader',
  components: {
    ElMenu,
    ElMenuItem,
    ElHeader,
    ElDrawer,
    ElButton,
    ElIcon,
    Menu,
    ArrowLeft // Добавляем иконку стрелки назад
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const authStore = useAuthStore();
    const isAuthenticated = computed(() => authStore.isAuthenticated);
    const drawerVisible = ref(false);
    const menuItems = [{
      label: 'Каталог',
      routeName: 'ProductCatalog',
      index: '1'
    }, {
      label: 'Потребности',
      routeName: 'NeedsList',
      index: '2'
    }, {
      label: 'Профиль',
      routeName: 'Profile',
      index: '3'
    }, {
      label: 'Выход',
      routeName: 'Login',
      index: '4'
    }];
    const isCategoryPage = computed(() => route.name === 'Category');
    const activeIndex = computed(() => {
      return menuItems.find(item => item.routeName === route.name)?.index || '4';
    });
    const currentPageTitle = computed(() => {
      if (route.name === 'Category') {
        return authStore.pageTitle || 'Категория';
      }
      const currentItem = menuItems.find(item => item.routeName === route.name);
      return currentItem ? currentItem.label : '';
    });
    const handleSelect = index => {
      const selectedItem = menuItems.find(item => item.index === index);
      if (selectedItem.routeName === 'Login') {
        authStore.logout();
      }
      router.push({
        name: selectedItem.routeName
      });
      drawerVisible.value = false; // Закрываем мобильное меню при выборе пункта
    };
    const goBackToCatalog = () => {
      router.push({
        name: 'ProductCatalog'
      });
    };
    const showHeader = computed(() => route.name !== 'Login');
    return {
      activeIndex,
      isAuthenticated,
      handleSelect,
      showHeader,
      menuItems,
      drawerVisible,
      currentPageTitle,
      isCategoryPage,
      // Добавляем вычисляемое свойство для проверки на страницу категории
      goBackToCatalog // Функция перехода на каталог
    };
  }
};