<template>
  <el-container>
    <el-form ref="createForm" :model="createForm" label-width="180px">
      <el-form-item label="Имя клиента" required :rules="[{ required: true, message: 'Введите имя клиента', trigger: 'blur' }]">
        <el-input v-model="createForm.clientName" placeholder="Введите имя клиента"></el-input>
      </el-form-item>

      <el-form-item label="Номер телефона" required>
        <el-input v-model="createForm.phoneNumber" v-maska data-maska="+7 ### ###-##-##" placeholder="+7 900 00-00-00"></el-input>
      </el-form-item>

      <el-form-item label="Город эксплуатации" required>
        <el-select v-model="createForm.city" filterable placeholder="Выберите город">
          <el-option
            v-for="item in cities"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Введите ИНН компании" required>
        <el-input v-model="createForm.inn" placeholder="Введите ИНН" @input="fetchCompanies"></el-input>
      </el-form-item>

      <el-form-item label="Компания" v-if="companies.length > 0" required>
        <el-select v-model="createForm.company" filterable placeholder="Выберите компанию">
          <el-option
            v-for="company in companies"
            :key="company.value"
            :label="company.label"
            :value="company.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Связь с клиентом" required>
        <el-select v-model="createForm.communication" placeholder="Можно связаться напрямую">
          <el-option
            v-for="communication in communications"
            :key="communication.id"
            :label="communication.name"
            :value="communication.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Комментарий">
        <el-input
          v-model="createForm.comment"
          style="width: 240px"
          :rows="2"
          type="textarea"
          placeholder="Комментарий"
        />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitForm" :disabled="!isFormValid">Создать</el-button>
        <el-button type="danger" @click="resetForm">Очистить</el-button>
      </el-form-item>
    </el-form>
  </el-container>
</template>

<script>
import axios from 'axios'
import { ElButton, ElContainer, ElForm, ElFormItem, ElInput, ElMessage, ElMessageBox, ElOption, ElSelect } from 'element-plus'
import { vMaska } from "maska"

export default {
  components: {
    ElContainer,
    ElForm,
    ElFormItem,
    ElInput,
    ElButton,
    ElSelect,
    ElOption
  },
  directives: { maska: vMaska },
  props: ['product'],
  data() {
    return {
      createForm: {
        clientName: '',
        phoneNumber: '',
        city: null,
        inn: '',
        company: null,
        communication: null,
        comment: '',
      },
      cities: [],
      communications: [],
      companies: [],
      shouldSaveDraft: true,
      formDataLoaded: false
    };
  },
  mounted() {
    this.fetchFormData();
    this.checkDraft();
  },
  watch: {
    createForm: {
      handler(newVal) {
        if (this.shouldSaveDraft) {
          localStorage.setItem('createFormDraft', JSON.stringify(newVal));
        }
      },
      deep: true
    }
  },
  computed: {
    isFormValid() {
      return this.createForm.clientName &&
            this.createForm.phoneNumber &&
            this.createForm.city &&
            this.createForm.inn &&
            this.createForm.company &&
            this.createForm.communication;
    }
  },
  methods: {
    fetchFormData() {
      axios.get('/api/form-data/')
        .then(response => {
          const data = response.data;
          this.cities = data.cities;
          this.communications = data.communications;
          this.formDataLoaded = true;
        })
        .catch(error => {
          console.error('Error fetching form data:', error);
        });
    },
    fetchCompanies() {
      const innLength = this.createForm.inn.length;
      if (innLength === 10 || innLength === 12) {
        this.fetchDaDataInfo(this.createForm.inn);
        this.createForm.company = null
      } else {
        console.log("INN must be exactly 10 or 12 digits long.");
      }
    },
    fetchDaDataInfo(inn) {
      axios.get(`/api/dadata-info/?inn=${inn}`)
        .then(response => {
          console.log('DaData response:', response.data);
          this.companies = response.data.data; // Adjust this based on the actual response structure
        })
        .catch(error => {
          console.error('Error fetching company information:', error);
        });
    },
    submitForm() {
      const formData = {
        client_name: this.createForm.clientName,
        phone_number: this.createForm.phoneNumber.replace(/\D/g, ''),
        inn: this.createForm.inn,
        company: this.createForm.company,
        city: this.createForm.city,
        product: this.product,
        communication: this.createForm.communication,
        comment: this.createForm.comment
      };

      axios.post('/api/create-need/', formData, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(() => {
        this.$emit('close');
        ElMessageBox.alert('Потребность успешно создана и отправлена на обработку. Как только изменится статус вам придет уведомление в Telegram.', 'Спасибо!');
        ElMessage({
          message: 'Успешно!',
          type: 'success',
        });
        // Очистка формы или перенаправление пользователя
        this.resetForm();
      })
      .catch(error => {
        console.error('Error creating need:', error.response.data);
         ElMessage({
          message: 'Потребность не создана!',
          type: 'error',
        });
      });
      localStorage.removeItem('createFormDraft');
    },
    resetForm() {
      this.shouldSaveDraft = false; // Не сохранять следующие изменения

      this.createForm = {
        clientName: '',
        phoneNumber: '',
        city: null,
        inn: '',
        company: null,
        communication: null,
        comment: ''
      };

      localStorage.removeItem('createFormDraft');
      
      this.$nextTick(() => {
        this.shouldSaveDraft = true; // Восстанавливаем сохранение после обновления данных
      });
    },
    checkDraft() {
      const draft = localStorage.getItem('createFormDraft');
      if (draft) {
        ElMessageBox.confirm(
          'Восстановить прошлые данные?', 
          'Черновик найден', {
            confirmButtonText: 'Да',
            cancelButtonText: 'Нет',
            type: 'warning'
          }
        ).then(() => {
          this.createForm = JSON.parse(draft);
          localStorage.removeItem('createFormDraft'); // Очистка черновика после восстановления
        }).catch(() => {
          localStorage.removeItem('createFormDraft'); // Удаление черновика, если пользователь выбрал "Нет"
        });
      }
    }
  }
};
</script>


<style scoped>
</style>
