<template>
  <el-form @submit.prevent="changePassword">
    <el-form-item label="Старый пароль">
      <el-input v-model="passwords.old_password" type="password" clearable style="width: 200px" autocomplete="current-password" show-password/>
    </el-form-item>
    <el-form-item label="Новый пароль">
      <el-input v-model="passwords.new_password" type="password" clearable style="width: 200px" show-password/>
    </el-form-item>
    <el-button type="primary" @click="changePassword">Сменить пароль</el-button>
  </el-form>
</template>

<script>
import axios from 'axios'
import { ElButton, ElForm, ElFormItem, ElInput, ElMessage } from 'element-plus'

export default {
  components: {
    ElForm,
    ElFormItem,
    ElInput,
    ElButton
  },
  data() {
    return {
      passwords: {
        old_password: '',
        new_password: ''
      }
    };
  },
  methods: {
    async changePassword() {
      try {
        const response = await axios.put('/api/change-password/', this.passwords);
				console.log(response)
        ElMessage.success('Пароль успешно изменен!');
				this.$emit('password-changed');
      } catch (error) {
        ElMessage.error('Ошибка при изменении пароля.');
      }
    }
  }
};
</script>
