<template>
  <el-container>
    <el-main>
      <el-table :data="needs" style="width: 100%" :row-class-name="rowClassName">
        <el-table-column prop="created_at" label="Дата создания" width="130">
          <template #default="{ row }">
            {{ formatDate(row.created_at) }}
          </template>
        </el-table-column>
        <el-table-column prop="company.name" label="Компания" min-width="150"/>
        <el-table-column prop="product.name" label="Техника" min-width="150"/>
        <el-table-column prop="status.name" label="Статус" width="100"/>
        <el-table-column label="Действия" width="120">
          <template #default="{ row }">
            <el-button v-if="row.calculation_id" type="text" @click="openCalculation(row)">Расчет</el-button>
            <el-button v-if="row.has_commercial_offer" type="text" @click="openOffers(row)">КП</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-drawer v-model="calculationModalVisible" size="50%" :title="drawerTitle" @close="handleDrawerClose">
        <Calculation-detail v-if="calculationData" :company="company" :calculation-data="calculationData" :calculation-id="calculationId" @close-drawer="calculationModalVisible=false"/>
      </el-drawer>
      <el-drawer v-model="offersModalVisible" size="50%" :title="drawerOffersTitle" @close="handleDrawerClose">
        <need-offers v-if="offers" :company="company" :offers="offers" :calculation-id="calculationId" />
      </el-drawer>
    </el-main>
  </el-container>
</template>

<script>
import CalculationDetail from '@/components/CalculationDetail.vue'
import NeedOffers from '@/components/NeedOffers.vue'
import axios from 'axios'
import { format } from 'date-fns'
import { ElButton, ElContainer, ElDrawer, ElMain, ElTable, ElTableColumn } from 'element-plus'

export default {
  components: {
    ElContainer,
    ElMain,
    ElTable,
    ElTableColumn,
    ElButton,
    ElDrawer,
    CalculationDetail,
    NeedOffers
  },
  data() {
    return {
      needs: [],
      calculationModalVisible: false,
      calculationData: null,
      drawerTitle: '',
      calculationId: 0,
      company: '',
      offersModalVisible: false,
      offers: null,
      drawerOffersTitle: '',
    }
  },
  mounted() {
    this.fetchNeeds();
  },
  methods: {
    fetchNeeds() {
      axios.get('/api/needs/')
        .then(response => {
          this.needs = response.data;
        })
        .catch(error => {
          console.error('Error fetching needs:', error);
        });
    },
    formatDate(date) {
      return format(new Date(date), 'dd.MM.yyyy HH:mm');
    },
    openCalculation(row) {
      this.calculationId = row.calculation_id;
      this.company = row.company.name;
      this.drawerTitle = `Расчет для ${row.company.name}`;
      axios.get(`/api/calculation-detail/?id=${row.calculation_id}`)
        .then(response => {
          this.calculationData = response.data;
          this.calculationModalVisible = true;
        })
        .catch(error => {
          console.error('Error fetching calculation details:', error);
          this.calculationData = null;
          this.calculationModalVisible = false;
        });
    },
    openOffers(row) {
      this.calculationId = row.calculation_id;
      this.company = row.company.name;
      this.drawerOffersTitle = `Коммерческие предложения для ${row.company.name}`;
      axios.get(`/api/commercial-offers/${row.calculation_id}`)
        .then(response => {
          this.offers = response.data;
          this.offersModalVisible = true;
        })
        .catch(error => {
          console.error('Error fetching calculation details:', error);
          this.calculationData = null;
          this.offersModalVisible = false;
        });
    },
    handleDrawerClose() {
      this.calculationData = null;
      this.offers = null;
    }
  }
}
</script>

<style>
@media (max-width: 768px) {
  .mobile-hide {
    display: none;
  }
}
</style>
