<template>
	<el-container>
		<el-main>
			<el-form :model="user" @submit.prevent="updateProfile" label-width="90px">
				<el-form-item label="Имя">
					<el-input v-model="user.first_name" style="width: 200px"/>
				</el-form-item>
				<el-form-item label="Email">
					<el-input v-model="user.email" style="width: 200px"/>
				</el-form-item>
				<el-form-item label="Телефон">
					<el-input v-model="user.phone" v-maska data-maska="+7 ### ###-##-##" clearable style="width: 200px"></el-input>
				</el-form-item>
				<el-form-item label="Telegram ID">
					<el-input v-model="user.telegram_id" style="width: 200px"/>
				</el-form-item>
				<el-button type="primary" @click="updateProfile">Сохранить</el-button>
				<el-button type="danger" @click="dialogVisible = true">Сменить пароль</el-button>
			</el-form>

			<!-- Модальное окно для смены пароля -->
			<el-dialog
				title="Смена пароля"
				v-model="dialogVisible"
				@close="dialogVisible = false">
				<change-password @password-changed="dialogVisible = false"/>
			</el-dialog>
		</el-main>
	</el-container>
</template>

<script>
import ChangePassword from '@/components/ChangePassword.vue'
import axios from 'axios'
import { ElButton, ElContainer, ElDialog, ElForm, ElFormItem, ElInput, ElMain, ElMessage } from 'element-plus'
import { vMaska } from "maska"

export default {
	components: {
		ElMain,
		ElContainer,
    ElForm,
    ElFormItem,
    ElInput,
		ElButton,
    ElDialog,
    ChangePassword
  },
	directives: { maska: vMaska },
  data() {
    return {
      user: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        telegram_id: ''
      },
      dialogVisible: false
    };
  },
	created() {
    this.fetchUserProfile();
  },
  methods: {
		async fetchUserProfile() {
      try {
        const response = await axios.get('/api/profile/');
        this.user = response.data;
      } catch (error) {
				ElMessage({
					message: 'Ошибка при загрузке данных профиля.',
					type: 'warning',
				})
      }
    },
    async updateProfile() {
      try {
				this.user.phone = this.user.phone.replace(/\D/g, '');
        const response = await axios.put('/api/update-profile/', this.user);
				console.log(response)
				ElMessage({
					message: 'Профиль успешно обновлен!',
					type: 'success',
				})
      } catch (error) {
				ElMessage({
					message: 'Ошибка при обновлении профиля.',
					type: 'error',
				})
      }
    },
  }
};
</script>
