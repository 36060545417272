<template>
  <div v-if="showHeader" class="app-header">
    <el-header>
      <!-- Если мы на странице Category, показываем кнопку "Назад", иначе кнопку меню -->
      <div v-if="isAuthenticated" class="mobile-header">
        <el-button v-if="isCategoryPage" @click="goBackToCatalog">
          <el-icon><ArrowLeft /></el-icon> <!-- Иконка стрелки назад -->
        </el-button>
        <el-button v-else @click="drawerVisible = true">
          <el-icon><Menu /></el-icon> <!-- Иконка меню -->
        </el-button>
        <h1 class="page-title">{{ currentPageTitle }}</h1>
      </div>
    </el-header>

    <el-drawer v-model="drawerVisible" direction="ltr" size="60%">
      <el-menu :default-active="activeIndex" class="el-menu-vertical" mode="vertical" @select="handleSelect">
        <el-menu-item v-for="item in menuItems" :key="item.index" :index="item.index">{{ item.label }}</el-menu-item>
      </el-menu>
    </el-drawer>
  </div>
</template>

<script>
import { useAuthStore } from '@/stores/auth'
import { ArrowLeft, Menu } from '@element-plus/icons-vue'
import { ElButton, ElDrawer, ElHeader, ElIcon, ElMenu, ElMenuItem } from 'element-plus'
import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export default {
  name: 'AppHeader',
  components: {
    ElMenu,
    ElMenuItem,
    ElHeader,
    ElDrawer,
    ElButton,
    ElIcon,
    Menu,
    ArrowLeft  // Добавляем иконку стрелки назад
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const authStore = useAuthStore();
    const isAuthenticated = computed(() => authStore.isAuthenticated);
    const drawerVisible = ref(false);

    const menuItems = [
      { label: 'Каталог', routeName: 'ProductCatalog', index: '1' },
      { label: 'Потребности', routeName: 'NeedsList', index: '2' },
      { label: 'Профиль', routeName: 'Profile', index: '3' },
      { label: 'Выход', routeName: 'Login', index: '4' }
    ];

    const isCategoryPage = computed(() => route.name === 'Category');

    const activeIndex = computed(() => {
      return menuItems.find(item => item.routeName === route.name)?.index || '4';
    });

    const currentPageTitle = computed(() => {
      if (route.name === 'Category') {
        return authStore.pageTitle || 'Категория';
      }
      const currentItem = menuItems.find(item => item.routeName === route.name);
      return currentItem ? currentItem.label : '';
    });

    const handleSelect = (index) => {
      const selectedItem = menuItems.find(item => item.index === index);
      if (selectedItem.routeName === 'Login') {
        authStore.logout();
      }
      router.push({ name: selectedItem.routeName });
      drawerVisible.value = false;  // Закрываем мобильное меню при выборе пункта
    };

    const goBackToCatalog = () => {
      router.push({ name: 'ProductCatalog' });
    };

    const showHeader = computed(() => route.name !== 'Login');

    return {
      activeIndex,
      isAuthenticated,
      handleSelect,
      showHeader,
      menuItems,
      drawerVisible,
      currentPageTitle,
      isCategoryPage,  // Добавляем вычисляемое свойство для проверки на страницу категории
      goBackToCatalog  // Функция перехода на каталог
    };
  }
};
</script>

<style scoped>
.app-header {
  .el-drawer__body {
    padding: 0;
  }
  .el-header {
    height: auto;
  }
  .mobile-header {
    display: flex;
    align-items: center;
  }
  .page-title {
    font-size: 18px;
    font-weight: 500;
    margin-left: 20px;
  }
}
</style>
