<template>
  <el-container class="login-container">
    <el-form @submit.prevent="login"> <!-- Удален .native, используется только @submit.prevent -->
      <el-form-item label="Логин: " required>
        <el-input v-model="loginForm.username" autocomplete="username" placeholder="Enter your username"></el-input>
      </el-form-item>
      <el-form-item label="Пароль:" required>
        <el-input type="password" v-model="loginForm.password" autocomplete="current-password" placeholder="Enter your password" show-password></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="submit" native-type="submit">Login</el-button> <!-- Уточнен type="submit" -->
      </el-form-item>
      <el-form-item>
        <el-alert v-if="errorMessage" type="error" :closable="false">{{ errorMessage }}</el-alert>
      </el-form-item>
    </el-form>
  </el-container>
</template>


<script>
import { useAuthStore } from '@/stores/auth'
import axios from 'axios'
import { ElAlert, ElButton, ElContainer, ElForm, ElFormItem, ElInput } from 'element-plus'
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'

export default {
  components: {
    ElContainer,
    ElForm,
    ElFormItem,
    ElButton,
    ElAlert,
    ElInput
  },
  setup() {
   const loginForm = reactive({
      username: '',
      password: ''
    });
    const errorMessage = ref('');
    const router = useRouter();
    const authStore = useAuthStore();

    const login = async () => {
      try {
        // Напрямую используем loginForm без .value
        const response = await axios.post('/api/auth/', loginForm , {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        authStore.login(response.data.token);
        router.push({ name: 'ProductCatalog' });
      } catch (error) {
        if (error.response && error.response.status === 400) {
          errorMessage.value = error.response.data.error || 'Login failed';
        } else {
          errorMessage.value = 'An unknown error occurred.';
        }
      }
    };


    return {
      loginForm,
      errorMessage,
      login
    };
  }
};
</script>



<style scoped>
.login-container {
  max-width: 400px;
  margin: 50px auto;
}
</style>
