<template>
  <el-table :data="offers" style="width: 100%" @row-click="handleRowClick">
    <el-table-column label="Дата создания" width="130">
      <template #default="{ row }">
        {{ formatDate(row.created_at) }}
      </template>
    </el-table-column>
    <el-table-column label="Общая цена" width="150">
      <template #default="{ row }">
        {{ formatCurrency(row.delivery_cost + row.sale_price) }}
      </template>
    </el-table-column>
    <el-table-column prop="delivery" label="Доставка" min-width="150"/>
  </el-table>
</template>

<script>
import { ElTable, ElTableColumn, ElMessage, ElLoading } from 'element-plus';
import axios from 'axios';
import { format } from 'date-fns';
import { saveAs } from 'file-saver';
import { formatCurrency } from '@/utils';

export default {
  name: 'NeedOffers',
  props: {
    offers: {
      type: Array,
      required: true
    }
  },
  components: {
    ElTable,
    ElTableColumn
  },
  methods: {
    formatDate(date) {
      return format(new Date(date), 'dd.MM.yyyy HH:mm');
    },
    formatCurrency(value) {
      return formatCurrency(value);
    },
    async handleRowClick(row) {
      // Используем requestAnimationFrame для предотвращения ResizeObserver ошибки
      requestAnimationFrame(() => {
        this.downloadOffer(row);
      });
    },
    async downloadOffer(offer) {
      const loading = ElLoading.service({
        lock: true,
        text: 'Создание КП...',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      const data = { ...offer.pdf_data, number: offer.id };

      try {
        const response = await axios.post('https://crm.liftnet.ru/print-pdf/agent/', data, {
          responseType: 'blob'
        });

        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
        saveAs(pdfBlob, 'Коммерческое_Предложение.pdf');
      } catch (error) {
        console.error('Ошибка при скачивании КП:', error);
        ElMessage({
          message: 'Ошибка при скачивании КП',
          type: 'error',
        });
      } finally {
        loading.close();
      }
    }
  }
}
</script>

<style scoped>
/* Добавьте стили, если необходимо */
</style>
