export function formatCurrency(value, type = 'currency', currency = 'RUB') {
    if (type === 'number') {
        return parseFloat(value).toFixed(2) + ' ₽';
    }
    return new Intl.NumberFormat('ru-RU', {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
    }).format(value);
}

export function roundToNearestTenThousand(value) {
    return Math.round(value / 10000) * 10000;
}

export function roundToNearestFiveThousand(value) {
    return Math.round(value / 5000) * 5000;
}

export function formatDeadline(days) {
    if (days % 10 === 1 && days % 100 !== 11) {
        return `${days} день`;
    } else if ([2, 3, 4].includes(days % 10) && ![12, 13, 14].includes(days % 100)) {
        return `${days} дня`;
    } else {
        return `${days} дней`;
    }
}

export function formattedPhoneNumber(phoneNumber) {
    const rawNumber = String(phoneNumber);
    const regex = /^7(\d{3})(\d{3})(\d{2})(\d{2})$/;
    const match = rawNumber.match(regex);
    if (match) {
        return `+7 ${match[1]} ${match[2]}-${match[3]}-${match[4]}`;
    }
    return rawNumber;
}