// stores/auth.js
import { defineStore } from 'pinia'

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        isAuthenticated: !!localStorage.getItem('authToken'),
        pageTitle: '',
        telegramUserId: null
    }),
    actions: {
        setAuthenticated(isAuth) {
            this.isAuthenticated = isAuth;
        },
        login(token) {
            localStorage.setItem('authToken', token);
            this.setAuthenticated(true);
        },
        logout() {
            localStorage.removeItem('authToken');
            this.setAuthenticated(false);
        },
        setTitlePage(title) {
            this.pageTitle = title; // Метод для установки заголовка страницы
        },
        setTelegramUserId(id) {
            this.telegramUserId = id;
        }
    }
});